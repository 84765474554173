var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('salary-calculation',{attrs:{"dialog":_vm.salaryCalculation},on:{"updated":function (_) {
          _vm.salaryCalculation = _;
          _vm.getSalaries();
        }}}),_c('v-row',{},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"se"}},[_vm._v("بحث")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"comp"}},[_vm._v(_vm._s(_vm.$t('select-company')))]),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.companies,"item-text":"name","label":_vm.$t('select-company'),"item-value":"id","loading":_vm.loading,"no-data-text":_vm.$t('no-data'),"solo":"","return-object":"","hide-details":"auto"},on:{"change":function($event){return _vm.getSalaries()}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"transparent"},[_c('v-card-title',[_vm._v(" الرواتب "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.salaryCalculation = !_vm.salaryCalculation}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" اضافة ")],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.salaries,"no-data-text":_vm.$t('no-data'),"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created)))+" ")]}},{key:"item.isPaid",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPaid ? 'نعم' : 'لا')+" ")]}},{key:"item.pay",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.pay(item)}}},[_vm._v("دقع")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }