<template>
  <div>
    <salary-calculation
      :dialog="salaryCalculation"
      @updated="
        (_) => {
          salaryCalculation = _;
          getSalaries();
        }
      "
    />
    <v-row class="">
      <v-col cols="4">
        <label for="se">بحث</label>
        <v-text-field
          hide-detials="auto"
          class="mt-2"
          :label="$t('search')"
          id="se"
          solo
          clearable
          v-model="options.Search"
          append-icon="mdi-magnify"
          type="search"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <label for="comp">{{ $t('select-company') }}</label>
        <v-autocomplete
          class="mt-2"
          v-model="company"
          :items="companies"
          item-text="name"
          :label="$t('select-company')"
          item-value="id"
          :loading="loading"
          :no-data-text="$t('no-data')"
          solo
          return-object
          @change="getSalaries()"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12">
        <v-card class="transparent">
          <v-card-title>
            الرواتب
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="salaryCalculation = !salaryCalculation"
              outlined
            >
              <v-icon>mdi-plus</v-icon>
              اضافة
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table
disable-pagination
              :loading="loading"
              :headers="headers"
              :items="salaries"
              :no-data-text="$t('no-data')"
              :hide-default-footer="true"
            >
             <template v-slot:item.created="{ item }">
                 {{ $service.formatDate(new Date(item.created)) }}
             </template>
             <template v-slot:item.isPaid="{ item }">
                 {{ item.isPaid ? 'نعم' : 'لا' }}
             </template>
             <template v-slot:item.pay="{ item }">
                 <v-btn color="secondary" text @click="pay(item)">دقع</v-btn>
             </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SalaryCalculation from "./SalaryCalculation.vue";
export default {
  components: {
    SalaryCalculation,
  },
  data() {
    return {
      loading: false,
      options: {
        Search: "",
        CompanyId: "",
      },
      companies: [],
      company: {},
      salaryCalculation: false,
      salaries: [],
      headers: [
        {
          text: "الشخص",
          align: "start",
          sortable: false,
          value: "user.userName",
        },

        { text: "الراتب الثابت", value: "defaultSalary" },
        { text: "تم دفعه", value: "amountPaid" },
        { text: "إجمالي الحوافز", value: "totalIncentives" },
        { text: "الخصومات", value: "totalDeductions" },
        { text: "المبالغ المسحوبة", value: "totalWithdrawnAmounts" },
        { text: "ذمم التحويل", value: "totalTransferReceivables" },
        { text: "الاجمالي", value: "totalSalary" },
        { text: "تم دفعه", value: "isPaid" },
        { text: "التاريخ", value: "created" },
        { text: "", value: "pay", sortable: false },
      ],
    };
  },
  methods: {
    getSalaries() {
      this.loading = true;
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null && val !== undefined) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http
        .get("/Salaries" + filter.replace("?&", "?"))
        .then((salaries) => {
          this.salaries = salaries.data.data;
          for (let i = 0; i < this.salaries.length; i++) {
            Object.keys(this.salaries[i]).forEach((key) => {
                if(typeof this.salaries[i][key] == "number"){
                    this.salaries[i][key] = "IQD " + this.$service.formatCurrency(this.salaries[i][key])
                }
            })
          }
          this.loading = false;
        })
        .catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message)
          })
    },
  },
  watch: {
    "options.Search"() {
      this.getSalaries();
    },
  },
  async created() {
    this.$http
      .get("/Companies",{
          params:{
            OfficeId: this.$store.state.userInfo.office.id
          }
        })
      .then((res) => {
        this.companies = res.data.result;
        this.getSalaries();
      })
      .catch(() => {
        this.$store.commit("UPDATE_SNACKBAR", true);
      });
  },
};
</script>